<template>
  <div>
    <div class="row flex center">
      <div class="col-md-6 col-lg-6 col-xl-3">
        <!--begin: Stats Widget 19-->
        <div
          class="card card-custom card-rounded bg-light-success card-stretch gutter-b"
        >
          <!--begin::Body-->
          <div class="card-body my-3">
            <div>
              <h3
                class="font-weight-bold font-size-sm"
                style="display: inline-block"
              >
                <template v-if="stats !== null">
                  <span
                    class="text-primary font-size-h2 font-weight-bolder mr-2"
                  >
                    {{ stats.account.impression_share.toFixed(0) }}%</span
                  >
                </template>
                <Loader :newStyle="1" v-else :width="100" />
              </h3>
              <span class="font-weight-bold text-muted font-size-sm"
                >Impression score (0 - 100%)</span
              >
            </div>
            <div class="progress progress-xs mt-7 bg-success-o-60 my-3">
              <template v-if="stats !== null">
                <div
                  :class="
                    `progress-bar bg-${
                      stats.account.impression_share < 60
                        ? stats.account.impression_share < 20
                          ? 'danger'
                          : 'warning'
                        : 'success'
                    }`
                  "
                  role="progressbar"
                  :style="
                    `width: ${stats.account.impression_share.toFixed(0)}%;`
                  "
                  :aria-valuenow="stats.account.impression_share.toFixed(0)"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </template>
              <Loader :newStyle="1" v-else :width="480" />
            </div>
          </div>
          <!--end:: Body-->
        </div>
        <!--end: Stats:Widget 19-->
      </div>
      <div class="col-md-6 col-lg-6 col-xl-3">
        <!--begin::Stats Widget 26-->
        <div
          class="card card-custom card-rounded bg-light-danger card-stretch gutter-b"
        >
          <!--begin::ody-->
          <div class="card-body my-3">
            <h3 class="text-primary font-weight-bold font-size-sm">
              <i
                class="flaticon-eye text-info"
                style="font-size: 20px; margin-right: 5px;"
              ></i>
              <template v-if="stats !== null">
                {{ stats.account.impressions }}
              </template>
              <Loader :newStyle="1" v-else :width="100" />
            </h3>
            <div class="font-weight-bold text-muted font-size-sm my-3">
              Views of your ads
            </div>
          </div>
          <!--end::Body-->
        </div>
        <!--end::Stats Widget 26-->
      </div>
      <div class="col-md-6 col-lg-6 col-xl-3">
        <!--begin::Stats Widget 26-->
        <div
          class="card card-custom card-rounded bg-light-danger card-stretch gutter-b"
        >
          <!--begin::ody-->
          <div class="card-body my-3">
            <h3 class="text-primary font-weight-bold font-size-sm">
              <i
                class="flaticon-black text-danger"
                style="font-size: 20px; margin-right: 5px;"
              ></i>
              <template v-if="stats !== null">
                {{ stats.account.clicks }}
              </template>
              <Loader :newStyle="1" v-else :width="100" />
            </h3>
            <div class="font-weight-bold text-muted font-size-sm my-3">
              Clicks of your ads
            </div>
          </div>
          <!--end::Body-->
        </div>
        <!--end::Stats Widget 26-->
      </div>
      <div class="col-md-6 col-lg-6 col-xl-3">
        <!--begin::Stats Widget 26-->
        <div
          class="card card-custom card-rounded bg-light-danger card-stretch gutter-b"
        >
          <!--begin::ody-->
          <div class="card-body my-3">
            <h3 class="text-primary font-weight-bold font-size-sm">
              <v-chip color="bg-success text-white" class="ma-3">AI</v-chip>
              <template v-if="terms !== null">
                {{ terms.length }}
              </template>
              <Loader :newStyle="1" v-else :width="100" />
            </h3>
            <div class="font-weight-bold text-muted font-size-sm my-3">
              Keywords that require learning
            </div>
          </div>
          <!--end::Body-->
        </div>
        <!--end::Stats Widget 26-->
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-xl-8">
        <KTPortlet title="Dashboard" data-app="ads">
          <template v-slot:toolbar v-if="start !== null && end !== null">
            {{ fstart }} - {{ fend }}
          </template>
          <template slot="body">
            <!-- <div
              style="display: flex; align-items: center; justify-content: space-evenly; margin: 20px; margin-top: 0;"
            >
              <div
                v-if="false"
                class="card"
                style="text-align: center; padding: 10px; background: none; border: none;"
              >
                <span>Searches on your keywords</span>
                <h3 style="margin: 0.5rem;">
                  {{ keywordSearches }}
                </h3>
                <span style="font-size: 10px;"
                  >Potential views of your ads</span
                >
              </div>
              <div
                class="card"
                style="text-align: center; padding: 10px; background: none; border: none;"
              >
                <span>Views of your ads </span>
                <h3 style="margin: 0.5rem;">
                  <template v-if="stats !== null">
                    {{ stats.account.impressions }}
                  </template>
                  <Loader :newStyle="1" v-else />
                </h3>
              </div>
              <div
                class="card"
                style="text-align: center; padding: 10px; background: none; border: none;"
              >
                <span>Clicks on your ads</span>
                <h3 style="margin: 0.5rem;">
                  <template v-if="stats !== null">
                    {{ stats.account.clicks }}
                  </template>
                  <Loader :newStyle="1" v-else />
                </h3>
              </div>
              <div
                v-if="showConversions"
                class="card"
                style="text-align: center; padding: 10px; background: none; border: none;"
              >
                <span>Conversions</span>
                <h3 style="margin: 0.5rem;">
                  <template v-if="stats !== null">
                    {{ stats.account.conversions }}
                  </template>
                  <Loader :newStyle="1" v-else />
                </h3>
                <span style="font-size: 10px;" v-if="stats !== null"
                  >Conversion rate:
                  <b
                    >{{
                      (
                        (stats.account.conversions / stats.account.clicks) *
                        100
                      ).toFixed(2)
                    }}%</b
                  ></span
                >
              </div>
            </div> -->
            <Loader
              v-if="
                stats === null ||
                  this.$tools.isNullOrUndefined(this.stats.account_daily) ||
                  this.$tools.isNullOrUndefined(chartData)
              "
              :inContent="true"
              :noText="true"
              >Loading...</Loader
            >
            <template v-else>
              <apexcharts
                style="color: var(--dark)"
                width="100%"
                height="500px"
                type="area"
                :options="chartOptions"
                :series="chartData"
              ></apexcharts>
            </template>
          </template>
        </KTPortlet>
      </div>
      <div class="col-lg-6 col-xl-4">
        <KTPortlet title="AI Learning" data-app="keywords">
          <template
            v-slot:toolbar
            v-if="terms !== null && termsLimited.length > 0"
          >
            <v-icon
              style="color: var(--info); text-align: left; cursor: pointer;"
              @click="showKeyworkAIHelp = !showKeyworkAIHelp"
              >mdi-help-circle</v-icon
            >
          </template>
          <template slot="body">
            <div
              v-if="terms !== null && termsLimited.length === 0"
              style="width: 100%; text-align: center;"
            >
              You've completed the AI training for today.<br />Come back
              tomorrow for more.<br /><br />
              <h2 style="transform: rotate(90deg);">:)</h2>
            </div>
            <div v-else>
              <div
                v-if="terms !== null && termsLimited.length > 0 && showKeyworkAIHelp"
                style="width: 100%; text-align: center; padding: 0 10px 10px 10px;"
              >
                <v-icon style="color: var(--info); text-align: left;"
                  >mdi-help-circle</v-icon
                >
                Press <i>Yes/green</i> or <i>No/red</i> if the keyword matches
                your business. <br />This trains our AI for more effective
                advert placements.
              </div>
              <b-table-simple responsive>
                <b-thead>
                  <b-tr>
                    <b-th>Keyword</b-th>
                    <b-th></b-th>
                  </b-tr>
                </b-thead>
                <b-tbody v-if="terms === null || termsDone === null">
                  <b-tr v-for="i in 10" v-bind:key="i">
                    <b-th>
                      <Loader :newStyle="1" />
                    </b-th>
                    <b-td>
                      <Loader :newStyle="1" />
                    </b-td>
                  </b-tr>
                </b-tbody>
                <b-tbody v-else>
                  <b-tr
                    v-for="(term, index) in termsLimited"
                    v-bind:key="index"
                  >
                    <b-th style="width: calc(100% - 110px); text-align:left;"
                      >{{ term.term }}
                    </b-th>
                    <b-td
                      style="width: 110px; text-align:right;"
                      v-if="termsDone.indexOf(term.term) >= 0"
                    >
                      <v-chip
                        class="ma-2"
                        x-medium
                        color="bg-success text-white"
                        >Saved</v-chip
                      >
                    </b-td>
                    <b-td
                      style="width: 110px; text-align:right;"
                      v-else-if="termsBusy.indexOf(term.term) >= 0"
                    >
                      <v-chip class="ma-2" x-medium color="bg-info text-white"
                        >Saving</v-chip
                      >
                    </b-td>
                    <b-td style="width: 110px; text-align:right;" v-else>
                      <v-tooltip left v-if="showKeyworkAIHelp">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="setTerm(term, true)"
                            v-bind="attrs"
                            v-on="on"
                            elevation="2"
                            icon
                            class="bg-success text-light"
                          >
                            <i class="flaticon2-check-mark"></i
                          ></v-btn>
                        </template>
                        <span>'{{ term.term }}' is relevant</span>
                      </v-tooltip>
                      <v-btn
                        v-else
                        @click="setTerm(term, true)"
                        elevation="2"
                        icon
                        class="bg-success text-light"
                      >
                        <i class="flaticon2-check-mark"></i
                      ></v-btn>
                      <span style="width: 10px; display: inline-block;"></span>
                      <v-tooltip left v-if="showKeyworkAIHelp">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="setTerm(term, false)"
                            v-bind="attrs"
                            v-on="on"
                            elevation="2"
                            icon
                            class="bg-danger text-light"
                          >
                            <i class="flaticon2-cross"></i
                          ></v-btn>
                        </template>
                        <span>'{{ term.term }}' is <b>not</b> relevant</span>
                      </v-tooltip>
                      <v-btn
                        v-else
                        @click="setTerm(term, false)"
                        elevation="2"
                        icon
                        class="bg-danger text-light"
                      >
                        <i class="flaticon2-cross"></i
                      ></v-btn>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Loader from "@/views/partials/content/Loader.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
//import Swal from "sweetalert2";
import TLib from "./lib";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
//import ApiService from "@/common/api.service";

export default {
  components: {
    KTPortlet,
    Loader,
    apexcharts: VueApexCharts
  },
  watch: {},
  data() {
    return {
      showKeyworkAIHelp: false,
      termsBusy: [],
      termsDone: [],
      stats: null,
      usage: null,
      showConversions: false,
      terms: null,
      start: null,
      end: null,
      chartOptions: {
        colors: ["#0091ea", "#6200ea", "#00c853", "#ffd600"],
        chart: {
          type: "area",
          height: 350
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "smooth"
        },

        xaxis: {
          labels: {
            formatter: function(value, timestamp) {
              return moment(new Date(timestamp)).format("DD/MM/YYYY");
            }
          },
          type: "datetime",
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          tickAmount: 4,
          floating: false,

          labels: {
            /*formatter: function(value) {
              return (value * (value < 0 ? -1 : 1)).toFixed(2) + " GB";
            },*/
            offsetY: -7,
            offsetX: 0
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        fill: {
          opacity: 0.5
        },
        tooltip: {
          x: {
            format: "datetime"
          },
          fixed: {
            enabled: false,
            position: "topRight"
          }
        },
        grid: {
          yaxis: {
            lines: {
              offsetX: -30
            }
          },
          padding: {
            left: 20
          }
        }
      }
    };
  },
  methods: {
    setTerm(term, wanted) {
      this.$data.termsBusy.push(term.term);
      const self = this;
      TLib.setAdBudTerm(this, this.$route.params.serviceId, term.term, wanted)
        .then(() => {
          self.$data.termsDone.push(term.term);
          //console.log('REMOVE TERM')
          /*for (let i = 0; i < self.terms.length; i++) {
            //console.log('REMOVE TERM:', `${self.terms[i].term} === ${term.term}`)
            if (self.terms[i].term === term.term) {
              //console.log('REMOVE TERM:', `${self.terms[i].term} === ${term.term} : REMOVE`)
              return;
            }
          }*/
          if (self.termsLimited.length === self.termsBusy.length) {
            self.$data.termsDone = null;
            for (let i = 0; i < self.terms.length; i++) {
              if (self.termsBusy.indexOf(self.terms[i].term) >= 0) {
                self.terms.splice(i, 1);
                i = 0;
              }
            }
            self.$nextTick(() => {
              self.$data.termsBusy = [];
              self.$data.termsDone = [];
              self.$data.showKeyworkAIHelp = false;
            });
          }
        })
        .catch(self.$log.error);
    },
    updateService(serviceId) {
      let self = this;
      self.$data.stats = null;
      self.$data.terms = null;
      TLib.getAdBudStats(self, serviceId)
        .then(z => {
          self.$data.stats = z.data;
          self.$data.start = z.start;
          self.$data.end = z.end;
        })
        .catch(self.$log.error);
      TLib.getAdBudTerms(self, serviceId)
        .then(z => {
          self.$data.terms = z;
        })
        .catch(self.$log.error);
      // return;
      // self.updateUsage(serviceId);
      // self.$data.service = null;
      // TLib.getSIM(this, serviceId)
      //   .then(x => {
      //     self.$nextTick(() => {
      //       self.$data.service = x;
      //     });
      //   })
      //   .catch(x => {
      //     self.$log.error(x);
      //     self.$log.error("Error loading content");
      //   });
    }
    /*updateUsage(serviceId) {
      let self = this;
      self.$data.usage = null;
      TLib.getUsage(this, serviceId)
        .then(x => {
          self.$nextTick(() => {
            self.$data.usage = x;
          });
        })
        .catch(x => {
          self.$log.error(x);
          self.$log.error("Error loading content");
        });
    }*/
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },

    termsLimited() {
      if (this.$tools.isNullOrUndefined(this.terms)) return [];
      let outputArr = [];
      for (let i = 0; i < this.terms.length; i++) {
        if (i > 8) break;
        outputArr.push(this.terms[i]);
      }
      return outputArr;
    },

    fstart() {
      return moment(this.start).format("DD/MM/YYYY");
    },
    fend() {
      return moment(this.end).format("DD/MM/YYYY");
    },

    chartData() {
      let data = [
        {
          name: "Clicks",
          data: []
        }
        /*{
          name: "Calls",
          data: []
        },*/
        /*{
          name: "Impressions",
          data: []
        }*/
      ];

      if (this.$tools.isNullOrUndefined(this.stats)) return null;
      if (this.$tools.isNullOrUndefined(this.stats.account_daily)) return null;

      for (let itemI of Object.keys(this.stats.account_daily)) {
        let item = this.stats.account_daily[itemI];
        data[0].data.push({
          x: item.date,
          y: item.clicks
        });
      }

      return data;
    }
  },
  beforeDestroy() {
    this.$eventBus.off(`switch-service-selected`);
    //this.$eventBus.off(`cache-crm:services`);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Advertising" },
      { title: "Dashboard" }
    ]);

    if (
      !this.$tools.isNullOrUndefined(this.config.charts) &&
      !this.$tools.isNullOrUndefined(this.config.charts.colours)
    )
      this.$data.chartOptions.colors = this.config.charts.colours;

    this.updateService(this.$route.params.serviceId);

    let self = this;

    self.$eventBus.on(`switch-service-selected`, x => self.updateService(x));
    //self.$eventBus.on(`cache-crm:services`, () => self.resync(false));
    //self.resync();
  }
};
</script>
